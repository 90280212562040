import React, { Component } from 'react';
import { connect } from 'react-redux';

import Form from 'react-bootstrap/Form';
import DateFilter from 'components/dateFilter';
import MAUsDashboard from 'pages/dashboard/containers/monthlyActiveUsers/components/mausDashboard';
import { mausActions } from 'pages/dashboard/containers/monthlyActiveUsers/actions/maus';
import DownloadFilter from 'pages/dashboard/containers/monthlyActiveUsers/components/downloadFilter';
import { getUserSites } from 'selectors';

const moment = require('moment');

class MonthlyActiveUsers extends Component {
  constructor(props) {
    super(props);
    const { userSites, ...rest } = props;
    this.state = {
      ...rest,
      maus_report_request: false,
      maus_start_date: moment().subtract(1, 'months'),
      maus_end_date: moment()
    };
  }

  componentDidMount() {
    this.props.dispatch(mausActions.fetchMAUS({ ...this.state, userSites: this.props.userSites }));
  }

  componentDidUpdate(prevProps) {
    if (this.props.userSites !== prevProps.userSites) {
      this.props.dispatch(mausActions.fetchMAUS({ ...this.state, userSites: this.props.userSites }));
    }
  }

  handleFilterChange = (value) => {
    this.setState({ maus_filter_type: value });
  };

  handleSetStartDate = (date) => {
    this.setState({ maus_start_date: date });
  };

  handleSetEndDate = (date) => {
    this.setState({ maus_end_date: date });
  };

  handleRefresh = (e) => {
    e.preventDefault();
    this.props.dispatch(mausActions.fetchMAUS({ ...this.state, userSites: this.props.userSites }));
  };

  setDefaultFilter = (e) => {
    e.preventDefault();
    this.setState({ maus_filter_type: 'this_year' }, () => {
      this.props.dispatch(mausActions.fetchMAUS({ ...this.state, userSites: this.props.userSites }));
    });
  };

  handleCourseAccessedFilter = (e) => {
    e.preventDefault();
    this.setState({ course_activity_filter: e.target.checked }, () => {
      this.props.dispatch(mausActions.fetchMAUS({ ...this.state, userSites: this.props.userSites }));
    });
  };

  requestMAUSReport = () => {
    this.props.dispatch(
      mausActions.fetchMAUsReportCSV(
        {
          maus_filter_type: this.state.maus_filter_type,
          maus_start_date: this.state.maus_start_date,
          maus_end_date: this.state.maus_end_date,
          course_activity_filter: this.state.course_activity_filter,
          userSites: this.props.userSites
        },
        false
      )
    );
  };

  requestMAUSDetailedReport = () => {
    this.props.dispatch(
      mausActions.fetchMAUsReportCSV(
        {
          maus_filter_type: this.state.maus_filter_type,
          maus_start_date: this.state.maus_start_date,
          maus_end_date: this.state.maus_end_date,
          course_activity_filter: this.state.course_activity_filter,
          userSites: this.props.userSites
        },
        true
      )
    );
  };

  handleDownloadFilter = (key) => {
    if (key === 'download_maus') {
      this.requestMAUSReport();
    } else if (key === 'download_user_report') {
      this.requestMAUSDetailedReport();
    }
  };

  render() {
    const { maus_data_labels, maus_data } = this.props;
    return (
      <div className="users-graph">
        <div>
          <h5>Monthly Active Learners details of {moment().format('YYYY')}</h5>
        </div>
        <div className="graph-insight-actions">
          <div className="custom-control custom-checkbox mb-3">
            <Form.Check className="mb-3 mb-sm-0">
              <Form.Check.Input
                type="checkbox"
                id="course_activity_filter"
                onChange={(e) => this.handleCourseAccessedFilter(e)}
                checked={this.state.course_activity_filter === true}
                value={this.state.course_activity_filter}
              />
              <Form.Check.Label style={{ fontSize: '0.75rem' }} htmlFor="course_activity_filter">
                Accessed Course Content
              </Form.Check.Label>
            </Form.Check>
            <div className="custom-filters">
              <DateFilter
                filterType={this.state.maus_filter_type}
                handleRefresh={this.handleRefresh}
                handleFilterChange={this.handleFilterChange}
                handleSetStartDate={this.handleSetStartDate}
                handleSetEndDate={this.handleSetEndDate}
                setDefaultFilter={this.setDefaultFilter}
              />
              <DownloadFilter handleFilterChange={this.handleDownloadFilter} />
            </div>
          </div>
          <MAUsDashboard maus_data_labels={maus_data_labels} maus_data={maus_data} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    maus_data_labels,
    maus_data,
    maus_filter_type,
    maus_start_date,
    maus_end_date,
    maus_quarter,
    maus_year,
    course_activity_filter,
    maus_detailed_report
  } = state.MAUS;

  const userSites = getUserSites(state);
  return {
    maus_data_labels,
    maus_data,
    maus_filter_type,
    maus_start_date,
    maus_end_date,
    maus_quarter,
    maus_year,
    course_activity_filter,
    maus_detailed_report,
    userSites
  };
}

const connectedMAUS = connect(mapStateToProps)(MonthlyActiveUsers);
export { connectedMAUS as MonthlyActiveUsers };
