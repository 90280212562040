import React from 'react';
import { Card } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import { IconBulb } from '@tabler/icons-react';
import { EDLY_SITE_PRICE } from 'config';
import { graphProperties } from 'helpers/constants';
import { connect } from 'react-redux';
import { getTheme } from 'selectors';

const BillingInfoCard = (props) => {
  const { theme } = props;

  const secondGraphColor = theme === 'dark' ? '#B8E1D9' : '#80D6C7';

  const data = {
    datasets: [
      {
        ...graphProperties[theme],
        data: [60, 40],
        backgroundColor: [graphProperties[theme].backgroundColor, secondGraphColor],
        borderWidth: 0
      }
    ]
  };

  const graphOptions = { events: [] };

  return (
    <Card className="billing-info-card mt-4">
      <Card.Body>
        <div className="mb-4">
          <div className="ms-1 mb-3 position-relative">
            <h4 className="mb-0 d-inline-block">
              <IconBulb color="#00AD90" size={34} className="me-3 mb-1" />
              How our Billing Works
            </h4>
          </div>
          <div className="mb-4">
            <p>
              Unlock growth with a new site for just <strong>${EDLY_SITE_PRICE}!</strong> Each new site is purchased
              separately and charged as a one-time fee.
            </p>
          </div>
          <hr class="half-rule" />
          <div className="mb-4">
            <p>
              Monthly active learners will be billed monthly as a summation of all the sites to make it easy for you.
            </p>
          </div>
        </div>
        <div className="summation-holder p-4">
          <div className="mb-4">
            <div className="donut-chart-container text-center mb-3">
              <Doughnut data={data} options={graphOptions} />
            </div>
            <div className="d-flex justify-content-center mb-2">
              <div className="d-flex align-items-center me-4">
                <div className="billing-info-major-indicator"></div>
                <small>
                  <strong>Site A:</strong> 60 MALs
                </small>
              </div>
              <div className="d-flex align-items-center">
                <div className="billing-info-minor-indicator"></div>
                <small>
                  <strong>Site B:</strong> 40 MALs
                </small>
              </div>
            </div>
          </div>

          <div className="billing-summary">
            <div className="d-flex justify-content-between mb-2">
              <span>Total MALs:</span>
              <span>100</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="fw-bold">Expected bill:</span>
              <span className="fw-bold">$300</span>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

function mapStateToProps(state) {
  const { theme } = getTheme(state);
  return {
    theme
  };
}

export default connect(mapStateToProps, null)(BillingInfoCard);
