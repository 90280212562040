import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Button, Accordion, Card, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { billingHistoryAction } from '../actions/billingHistory';

const moment = require('moment');

const DateFilter = (props) => {
  const { dispatch } = props;
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const filterContainerRef = useRef(null);
  const [filter, setFilter] = useState('');

  const handleFilterChange = (key) => {
    setFilter(key);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterContainerRef.current && !filterContainerRef.current.contains(event.target)) {
        setIsFilterOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const applyButtonHandler = async (e) => {
    setIsFilterOpen(false);

    // this month date in epoch using moment
    let dateStart = moment()
      .startOf('month')
      .unix();
    let dateEnd = moment()
      .endOf('month')
      .unix();
    if (filter === 'this_year') {
      dateStart = moment()
        .startOf('year')
        .unix();
      dateEnd = moment()
        .endOf('year')
        .unix();
    } else if (filter === 'last_month') {
      dateStart = moment()
        .subtract(1, 'month')
        .startOf('month')
        .unix();
      dateEnd = moment()
        .subtract(1, 'month')
        .endOf('month')
        .unix();
    } else if (filter === 'last_year') {
      dateStart = moment()
        .subtract(1, 'year')
        .startOf('year')
        .unix();
      dateEnd = moment()
        .subtract(1, 'year')
        .endOf('year')
        .unix();
    }

    dispatch(
      billingHistoryAction.fetchBillingHistory({
        search_key: `created<=${dateEnd} AND created>=${dateStart}`
      })
    );
  };

  const resetButtonHandler = async (e) => {
    dispatch(billingHistoryAction.fetchBillingHistory({}));
    setIsFilterOpen(false);
    setFilter('');
  };

  const filters = {
    this_year: 'This Year',
    last_year: 'Last Year',
    this_month: 'This Month',
    last_month: 'Last Month'
  };

  const filterListItems = Object.keys(filters).map((key, index) => {
    return (
      <ListGroup.Item
        action
        onClick={() => handleFilterChange(key)}
        key={key}
        style={{ fontSize: '0.75rem' }}
        className={filter === key ? 'active' : ''}
      >
        {filters[key]}
      </ListGroup.Item>
    );
  });

  return (
    <Fragment>
      <div ref={filterContainerRef} className="maus-filter-container">
        <Accordion className="maus-filter">
          <Card>
            <Card.Header onClick={() => setIsFilterOpen(!isFilterOpen)} className={isFilterOpen ? 'open' : ''}>
              {filters[filter] ? filters[filter] : 'Filter'}
              <FontAwesomeIcon icon={isFilterOpen ? faChevronUp : faChevronDown} />
            </Card.Header>
            <Accordion.Collapse
              className={isFilterOpen ? 'show' : 'd-none'}
              style={{ minWidth: '220px', zIndex: '100' }}
            >
              <Card.Body>
                <ListGroup className="mb-3">{filterListItems}</ListGroup>
                <div className="maus-filter-footer">
                  <Button variant="outline-primary" size="sm" onClick={resetButtonHandler}>
                    Reset
                  </Button>
                  <Button className="ms-2" variant="primary" size="sm" onClick={applyButtonHandler}>
                    Apply
                  </Button>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </Fragment>
  );
};

export default DateFilter;
