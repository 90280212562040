export const graphProperties = {
  dark: {
    backgroundColor: '#70c3b2',
    hoverBackgroundColor: '#7EC9BA',
    borderColor: '#8DCFC1',
    pointHoverBorderWidth: 1,
    pointRadius: 1,
    pointHitRadius: 10,
    fill: true,
    lineTension: 0.1,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: '#7EC9BA',
    pointBackgroundColor: '#7EC9BA',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: '#7EC9BA',
    pointHoverBorderColor: '#7EC9BA'
  },
  light: {
    backgroundColor: '#00AD90',
    hoverBackgroundColor: '#4DC6B1',
    borderColor: '#80D6C7',
    pointHoverBorderWidth: 1,
    pointRadius: 1,
    pointHitRadius: 10,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: '#479E87',
    pointBackgroundColor: '#479E87',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: '#479E87',
    pointHoverBorderColor: '#479E87'
  }
};

export const dashboardGraphColor = {
  light: ['#00AD90', '#8043F3', '#5CAEF9', '#367B79', '#EE85B5', '#E75B5C', '#8BDA94', '#5687F7', '#C03970'],
  dark: ['#70C3B2', '#8A3FFC', '#33B1FF', '#007D79', '#FF7EB6', '#FA4D56', '#FFF1F1', '#6FDC8C', '#D12771']
};

const DASHBOARD_ROUTES = ['/panel/dashboard', '/panel/settings/general'];
const DASHBOARD_BILLING = ['/panel/billing', '/panel/billing/details'];
const DASHBOARD_BENEFITS = ['/panel/benefits'];
const INSIGHT_ROUTES = ['/panel/insights/learners', '/panel/insights/courses', '/panel/settings/profile'];
const SETTING_PROTECTED_ROUTES = [
  '/panel/user',
  '/panel/settings/appearance',
  '/panel/settings/signin',
  '/panel/settings/analytics',
  '/panel/settings/integrations',
  '/panel/settings/sitedetails',
  '/panel/settings/mobileconfig',
  '/panel/settings/data_backup'
];

const PANEL_ADMIN = [...DASHBOARD_ROUTES, ...DASHBOARD_BENEFITS, ...INSIGHT_ROUTES, ...SETTING_PROTECTED_ROUTES];

export const USER_ROUTES = {
  panel_admin: PANEL_ADMIN,
  super_admin: [...PANEL_ADMIN, ...DASHBOARD_BILLING],
  insights_admin: [...DASHBOARD_ROUTES, ...INSIGHT_ROUTES],
  panel_restricted: ['/panel/401'],
  panel_user: [...DASHBOARD_ROUTES, '/panel/settings/profile']
};
