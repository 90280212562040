import React from 'react';
import { connect } from 'react-redux';

import { convertValueTo2DP } from 'helpers';
import BarChart from 'components/charts/barChart';
import BarChartTrend from 'components/charts/barChartTrend';
import PercentChangeBox from 'components/analytics/percentChangeBox';
import { Card, CardBody, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { IconInfoCircle } from '@tabler/icons-react';
import {
  getMAUsQuickStats,
  getMAUsQuickStatsLabels,
  getMAUsQuickStatsTotalCount,
  getMAUsQuickStatsPercentageChange
} from 'selectors';

const renderTooltip = (props) => (
  <Tooltip id="monthly-active-users" {...props}>
    Monthly active learners (MALs) refers to the number of unique learners who signed in to your site in the current
    calendar month. This includes users who created an account for the first time and users who accessed your site while
    already logged in.
  </Tooltip>
);

const MAUSAnalytics = (props) => {
  const { total_count, percent_change, data_labels, data_values, show_chart, trending, title } = props;

  return (
    <Card className="stats-box mb-4">
      <CardBody>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Card.Title as="h4" test-data-field="current-maus">
            {total_count}
          </Card.Title>
          <PercentChangeBox percent_change={convertValueTo2DP(percent_change)} />
          {show_chart &&
            (trending === 'true' ? (
              <BarChartTrend title="MALs Count" labels={data_labels} values={data_values} />
            ) : (
              <BarChart title="MALS Count" labels={data_labels} values={data_values} />
            ))}
        </div>
        <Card.Text>
          {title}
          <OverlayTrigger placement="bottom" overlay={renderTooltip}>
            <span id="monthly-active-users" className="ms-2">
              <IconInfoCircle stroke={1.5} />
            </span>
          </OverlayTrigger>
        </Card.Text>
      </CardBody>
    </Card>
  );
};

function mapStateToProps(state, ownProps) {
  const data_labels = getMAUsQuickStatsLabels(state);
  const data_values = getMAUsQuickStats(state);
  const total_count = getMAUsQuickStatsTotalCount(state);
  const percent_change = getMAUsQuickStatsPercentageChange(state);
  const show_chart = ownProps.show_chart;
  const trending = ownProps.trending;

  return {
    data_labels,
    data_values,
    total_count,
    percent_change,
    show_chart,
    trending
  };
}

export default connect(mapStateToProps)(MAUSAnalytics);
