import React from 'react';
import { Tooltip, OverlayTrigger, Card, CardBody } from 'react-bootstrap';
import { IconInfoCircle } from '@tabler/icons-react';
import CourseListItem from 'pages/insights/containers/analyticsSummaryContainer/components/courseListItem';

const renderTooltip = (props) => (
  <Tooltip id="course-enrollment" {...props}>
    Courses ranked by number of enrollments.
  </Tooltip>
);

const TopCourseEnrollments = (props) => {
  const { enrollment_results } = props;
  const courses = enrollment_results.slice(0, 10).map((course, index) => {
    return (
      <CourseListItem key={index} id={course.course_id} name={course.course_name} value={course.enrollment_count} />
    );
  });

  return (
    <Card>
      <CardBody>
        <h5>
          Top Course by Enrollment
          <OverlayTrigger placement="bottom" overlay={renderTooltip}>
            <span id="course-enrollment" className="ms-2">
              <IconInfoCircle stroke={1.5} />
            </span>
          </OverlayTrigger>
        </h5>
        <ul className="course-analytics-list">
          <li className="head-list-item">
            <span>Name</span>
            <span>Enrollments</span>
          </li>
          {courses.length > 0 ? courses : <li className="no-data">No Data Available</li>}
        </ul>
      </CardBody>
    </Card>
  );
};

export default TopCourseEnrollments;
