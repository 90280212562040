import React from 'react';
import { connect } from 'react-redux';

import LineChartTrend from 'components/charts/lineChartTrend';
import PercentChangeBox from 'components/analytics/percentChangeBox';
import { Card, CardBody, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { IconInfoCircle } from '@tabler/icons-react';
import {
  getCurrentTotalSiteCoursesTotalCount,
  getCurrentTotalSiteCoursesPercentageChange,
  getTotalSiteCoursesDataLabels,
  getTotalSiteCoursesDataValues
} from 'selectors';

const renderTooltip = (props) => (
  <Tooltip id="total-courses" {...props}>
    Total number of courses published on the platform.
  </Tooltip>
);

const TotalCourses = (props) => {
  const { data_labels, data_values, percent_change, total_count } = props;

  return (
    <Card className="stats-box">
      <CardBody>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Card.Title as="h3">{total_count}</Card.Title>
          <PercentChangeBox percent_change={percent_change} />
        </div>

        <Card.Text>
          Total Courses
          <OverlayTrigger placement="bottom" overlay={renderTooltip}>
            <span id="total-courses" className="ms-2">
              <IconInfoCircle stroke={1.5} />
            </span>
          </OverlayTrigger>
        </Card.Text>
        <LineChartTrend title="Courses Count" labels={data_labels} values={data_values} />
      </CardBody>
    </Card>
  );
};

function mapStateToProps(state) {
  const data_labels = getTotalSiteCoursesDataLabels(state);
  const data_values = getTotalSiteCoursesDataValues(state);
  const percent_change = getCurrentTotalSiteCoursesPercentageChange(state);
  const total_count = getCurrentTotalSiteCoursesTotalCount(state);

  return {
    data_labels,
    data_values,
    percent_change,
    total_count
  };
}

export default connect(mapStateToProps)(TotalCourses);
