import React, { useState } from 'react';
import { benefitsContent } from '../constants/benefitsContent';
import { Col, Row } from 'react-bootstrap';
import SearchInput from 'components/analytics/searchInput';
import BenefitList from './BenefitList';

function Benefits() {
  const [searchText, setSearchText] = useState('');
  const [displayedData, setDisplayedData] = useState(benefitsContent.slice(0, 6));
  const [visibleItemCount, setVisibleItemCount] = useState(6);
  const handleInputChange = (event) => {
    const searchText = event.target.value;
    const filteredData = benefitsContent.filter((item) => item.title.toLowerCase().includes(searchText.toLowerCase()));
    setSearchText(searchText);
    if (searchText.length > 0) {
      setVisibleItemCount(filteredData.length);
      setDisplayedData(filteredData);
    } else {
      setVisibleItemCount(6);
      setDisplayedData(benefitsContent.slice(0, 6));
    }
  };

  const handleLoadMore = () => {
    const newVisibleItemCount = visibleItemCount + 3;
    setVisibleItemCount(newVisibleItemCount);
    setDisplayedData(benefitsContent.slice(0, newVisibleItemCount));
  };

  return (
    <div className="pt-3">
      <Row className="mb-4">
        <Col sm="12" md="6">
          <h4 className="mb-md-0">Benefit</h4>
        </Col>
        <Col sm="12" md="6" align="end">
          <div className="d-flex justify-content-md-end align-items-center">
            <SearchInput label="Search" searchText={searchText} handleInputChange={handleInputChange} />
          </div>
        </Col>
      </Row>
      <BenefitList
        displayedData={displayedData}
        visibleItemCount={visibleItemCount}
        handleLoadMore={handleLoadMore}
        searchText={searchText}
      />
    </div>
  );
}

export default Benefits;
