import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from 'react-bootstrap';

import { billingHistoryAction } from 'pages/pricing/containers/invoicesContainer/actions/billingHistory';
import InvoiceListing from 'pages/pricing/containers/invoicesContainer/components/Invoice';
import DateFilter from 'pages/pricing/containers/invoicesContainer/components/datafilter';
import { pricingService } from 'services';
import { messageNotification } from 'helpers';

const InvoiceCenter = (props) => {
  const { dispatch } = props;
  const [disable, setDisable] = useState(false);
  useEffect(() => {
    dispatch(billingHistoryAction.fetchBillingHistory({}));
  }, [dispatch]);

  const exportAsPDF = (event) => {
    event.preventDefault();
    setDisable(true);

    pricingService
      .exportBillingHistory()
      .then((data) => {
        messageNotification('success', '', 'Report is being sent. You will receive an email shortly.');
        setDisable(false);
      })
      .catch((err) => {
        if (err.response) {
          messageNotification('danger', 'There was an error updating site theme', err.response);
        }
        setDisable(false);
      });
  };

  return (
    <div>
      <div className="mb-4 pb-2 d-flex justify-content-between align-items-center flex-lg-row flex-column">
        <h2 className="mb-0">Billing Details</h2>
        <Link style={{ color: 'none' }} to={'/panel/billing'}>
          <Button variant="link">Back to Billing Details</Button>
        </Link>
      </div>
      <h4 className="mb-4 d-flex align-items-center justify-content-between flex-column flex-lg-row">
        <span className="mb-lg-0 mb-2">Billing History</span>
        <div className="d-flex align-items-center gap-3">
          <DateFilter dispatch={dispatch} />
          <Button variant="outline-primary" onClick={exportAsPDF} size="sm" disabled={disable}>
            {disable ? 'Loading...' : 'Export As CSV'}
          </Button>
        </div>
      </h4>
      <InvoiceListing />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(null, mapDispatchToProps)(InvoiceCenter);
