import React from 'react';
import Switch from 'react-switch';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getTheme } from 'selectors';

const ToggleItem = ({ title, description, checked, onChange, theme }) => {
  return (
    <div>
      <Row className="d-flex align-items-center justify-content-between mb-3 switch-row">
        <Col xs="8">
          <h5>{title}</h5>
          <small>{description}</small>
        </Col>
        <Col xs="4" className="justify-content-end d-flex">
          <div className="switch-holder">
            <Switch
              width={45}
              height={24}
              id={title.toLowerCase()}
              checked={checked}
              onChange={onChange}
              uncheckedIcon={false}
              checkedIcon={false}
              offColor={theme === 'light' ? '#fff' : '#1E1E1E'}
              offHandleColor={theme === 'light' ? '#BFBFBF' : '#fff'}
              onHandleColor="#fff"
              onColor={theme === 'light' ? '#DD1F25' : '#ED6251'}
              className={theme === 'light' ? 'light-switch' : 'dark-switch'}
            />
            <label htmlFor={title.toLowerCase()}>{checked ? 'On' : 'Off'}</label>
          </div>
        </Col>
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  const { theme } = getTheme(state);
  return {
    theme
  };
}

export default connect(mapStateToProps, null)(ToggleItem);
