import React, { useState } from 'react';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import { useHistory } from 'react-router-dom';

import UserListing from 'pages/users/containers/usersContainer/components/usersListing';
import { getOrganization, getSiteUsers } from 'selectors';
import 'pages/users/containers/index.scss';
import { PLAN_FEATURES } from 'pages/users/containers/usersContainer/constants';
import { Button } from 'react-bootstrap';
import { BILLING_PAGE } from 'components/ctaBanner/constants';

const UsersCenter = (props) => {
  const [addModalShow, setAddModalShow] = useState(false);
  const [bulkModalShow, setBulkModalShow] = useState(false);
  const history = useHistory();

  const handleUserAddition = () => {
    const plan = props.organization['current_site_plan'];
    if (props.count <= PLAN_FEATURES[plan.toUpperCase()]['NUMBER_OF_REGISTERED_USERS']) {
      setAddModalShow(true);
      mixpanel.track('Add New User', {
        service: 'edly_panel',
        screen_name: 'panel_users',
        organization: props.organization.name
      });
    } else {
      history.push(`${BILLING_PAGE}/?redirect=panel`);
    }
  };

  return (
    <div>
      <div className="page-content-header">
        <h2>User Management</h2>
        <div>
          <Button
            variant="outline-primary"
            onClick={() => {
              setBulkModalShow(true);
            }}
          >
            Add bulk users
          </Button>
          <Button className="ms-3" onClick={handleUserAddition}>
            Add new user
          </Button>
        </div>
      </div>
      <div className="user-content">
        <UserListing
          bulkModalShow={bulkModalShow}
          setBulkModalShow={setBulkModalShow}
          addModalShow={addModalShow}
          setAddModalShow={setAddModalShow}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const organization = getOrganization(state);
  const { count } = getSiteUsers(state);

  return { organization, count };
}

export default connect(mapStateToProps)(UsersCenter);
