import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, ListGroup, Modal } from 'react-bootstrap';
import { IconInfoTriangle } from '@tabler/icons-react';
import {
  CTA_BANNER_FREE_TRIAL_MSG,
  CTA_BANNER_EXPIRED_MSG,
  CTA_BANNER_TRIAL_DETAIL_MSG,
  CTA_BANNER_EXPIRED_DETAIL_MSG,
  BILLING_PAGE
} from 'components/ctaBanner/constants/index';

const FreeTrialDetailModel = ({ show, handleClose }) => {
  return (
    <Modal show={show} size="lg" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Free trial details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          <ListGroup.Item className="border-0 ps-0 pe-0">
            Your first site is free for the 30 day free-trial period.
          </ListGroup.Item>
          <ListGroup.Item className="border-0 ps-0 pe-0">
            You may add up to 20 free users during the free-trial period.
          </ListGroup.Item>
          <ListGroup.Item className="border-0 ps-0 pe-0">
            Access to your free trial site is revoked 15 days after the free-trial period expires.
          </ListGroup.Item>
          <ListGroup.Item className="border-0 ps-0 pe-0">
            Upgrade to activated billing to add additional users and continue using your site.
          </ListGroup.Item>
          <ListGroup.Item className="border-0 ps-0 pe-0">
            Activate billing to enable purchase of multiple sites.
          </ListGroup.Item>
          <ListGroup.Item className="border-0 ps-0 pe-0">
            MALs will be charged as a summation of all sites.
          </ListGroup.Item>
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};
const CTABannerContent = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Alert variant={`${props.daysRemaining > 0 ? 'secondary' : 'danger'}`}>
      <div className="custom-alert-area">
        <div className="text-area">
          <IconInfoTriangle className="me-3" stroke={1.5} />
          <div className="text">
            <h6 className="mb-1">
              {props.daysRemaining > 0
                ? CTA_BANNER_FREE_TRIAL_MSG.replace('{dayRemainings}', props.daysRemaining)
                : CTA_BANNER_EXPIRED_MSG}
            </h6>
            <small>{props.daysRemaining > 0 ? CTA_BANNER_TRIAL_DETAIL_MSG : CTA_BANNER_EXPIRED_DETAIL_MSG}</small>
          </div>
        </div>
        <div>
          <Link className="alert-link mt-2 mt-lg-0 fw-semibold" to={BILLING_PAGE} onClick={handleShow}>
            View details
          </Link>
          <Link className="alert-link mt-2 mt-lg-0 ms-4 fw-semibold" to={BILLING_PAGE}>
            Activate billing
          </Link>
        </div>
        <FreeTrialDetailModel handleClose={handleClose} show={show} />
      </div>
    </Alert>
  );
};

export default CTABannerContent;
