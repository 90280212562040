import { billingHistoryConstant } from 'pages/pricing/containers/invoicesContainer/constants';

const initialBillingHistoryState = {
  customerId: '',
  invoiceList: '',
  hasMore: false,
  currentPage: 1
};

export const billingHistoryReducer = (state = initialBillingHistoryState, action) => {
  switch (action.type) {
    case billingHistoryConstant.FETCH_BILLING_HISTORY_SUCCESS: {
      const data = action.payload.data.data;
      return {
        customerId: data.customer,
        invoiceList: data.latest_invoice,
        hasMore: data.has_more,
        currentPage: action.payload?.currentPage || 1
      };
    }
    case billingHistoryConstant.FETCH_BILLING_HISTORY_FAILURE: {
      return {
        ...state,
        error: action.payload
      };
    }
    default:
      return state;
  }
};
