import React from 'react';
import { connect } from 'react-redux';

import { Card, CardBody, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { convertValueTo2DP } from 'helpers';
import LineChart from 'components/charts/lineChart';
import LineChartTrend from 'components/charts/lineChartTrend';
import PercentChangeBox from 'components/analytics/percentChangeBox';
import { IconInfoCircle } from '@tabler/icons-react';
import {
  getCourseCompletionsLabels,
  getCourseCompletionsStats,
  getCourseCompletionPercentageChange,
  getCurrentMonthCourseCompletionsCount
} from 'selectors';

const renderTooltip = (props) => (
  <Tooltip id="course-completions" {...props}>
    Total number of time all of the courses have been completed by the learners in the current calendar month.
  </Tooltip>
);

const CoursesCompletions = (props) => {
  const { month_count, percent_change, data_labels, data_values, show_chart, trending, title } = props;

  return (
    <Card className="stats-box">
      <CardBody>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Card.Title as="h3" test-data-field="current-completions">
            {month_count}
          </Card.Title>
          <PercentChangeBox percent_change={convertValueTo2DP(percent_change)} />
        </div>
        <Card.Text>
          {title}
          <OverlayTrigger placement="bottom" overlay={renderTooltip}>
            <span id="course-completions" className="ms-2">
              <IconInfoCircle stroke={1.5} />
            </span>
          </OverlayTrigger>
        </Card.Text>
        {show_chart &&
          (trending === 'true' ? (
            <LineChartTrend title="Completions Count" labels={data_labels} values={data_values} />
          ) : (
            <LineChart title="Completions Count" labels={data_labels} values={data_values} />
          ))}
      </CardBody>
    </Card>
  );
};

function mapStateToProps(state, ownProps) {
  const data_labels = getCourseCompletionsLabels(state);
  const data_values = getCourseCompletionsStats(state);
  const month_count = getCurrentMonthCourseCompletionsCount(state);
  const percent_change = getCourseCompletionPercentageChange(state);
  const show_chart = ownProps.show_chart;
  const trending = ownProps.trending;

  return {
    data_labels,
    data_values,
    percent_change,
    month_count,
    show_chart,
    trending
  };
}

export default connect(mapStateToProps)(CoursesCompletions);
