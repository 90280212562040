import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navbar, Nav, NavDropdown, Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { getTheme, getUserSettings } from 'selectors';
import UserGuideButton from 'components/demo/userGuideButton';
import { notificationActions } from 'pages/notifications/containers/notifications/actions';
import NotificationsDropdown from 'pages/notifications/containers/notifications/components/dropdown';
import {
  getEdlyServiceUrl,
  getWordpressBaseUrl,
  getEcommerceBaseUrl,
  getHelpUrl,
  getPanelBackendUrl,
  checkCurrentPath
} from 'helpers';
import { formatRole } from 'helpers/dashboard';
import { getOrganization } from 'selectors';
import MailChimpLogo from 'assets/images/mail-chimp-logo.svg';
import ZendeskLogo from 'assets/images/zendesk-logo.svg';
import GoogleAnalyticsLogo from 'assets/images/google-analytics-logo.svg';
import 'components/header/index.scss';
import { useLocation } from 'react-router-dom';
import { IconLayoutSidebarRightExpand, IconPower, IconUser, IconCategory, IconMenuDeep } from '@tabler/icons-react';
import { themeActions } from 'pages/dashboard/containers/themes/actions';
import { MAIN_ROUTES } from 'components/sidebar/contants';

const parse = require('html-react-parser');

const Header = (props) => {
  const {
    organization,
    name,
    email,
    profile_image,
    theme,
    panel_role = 'panel_restricted',
    services_notifications,
    display_status,
    updateServicesNotificationsStatus,
    handleLogout,
    setSideBarOpen = () => {},
    dispatch
  } = props;

  const htmlRef = useRef(document.documentElement);

  const toggleTheme = () => {
    const currentTheme = htmlRef.current.getAttribute('data-bs-theme');
    if (currentTheme === 'dark') {
      localStorage.setItem('theme', 'light');
      htmlRef.current.setAttribute('data-bs-theme', 'light');
      dispatch(themeActions.toogleThemeMode('light'));
    } else {
      localStorage.setItem('theme', 'dark');
      htmlRef.current.setAttribute('data-bs-theme', 'dark');
      dispatch(themeActions.toogleThemeMode('dark'));
    }
  };

  const clearTheme = () => {
    htmlRef.current.removeAttribute('data-bs-theme');
  };

  useEffect(() => {
    htmlRef.current.setAttribute('data-bs-theme', localStorage.getItem('theme') || 'light');
    return () => {
      clearTheme();
    };
  }, []);

  const param = useLocation();

  const wordPressUrl = getEdlyServiceUrl(organization, 'wordpress');
  const lmsUrl = getEdlyServiceUrl(organization, 'lms');
  const studioUrl = getEdlyServiceUrl(organization, 'studio');
  const ecommerceUrl = getEdlyServiceUrl(organization, 'ecommerce');
  const helpUrl = getHelpUrl();
  const mailChimpUrl = getEdlyServiceUrl(organization, 'mailchimp');
  const zenDeskUrl = getEdlyServiceUrl(organization, 'zendesk');
  const googleDataStudioUrl = getEdlyServiceUrl(organization, 'google_data_studio');

  const handleServicesNotificationsDisplayStatus = (notification_id) => {
    updateServicesNotificationsStatus(notification_id, false);
  };

  const notifications = services_notifications.map((notification, index) => {
    return (
      <div key={index} className={display_status[notification.id] ? `alert alert-${notification.type}` : 'd-none'}>
        <FontAwesomeIcon icon={Icons.faExclamationCircle} className="icon" />
        <span className="dismiss" onClick={() => handleServicesNotificationsDisplayStatus(notification.id)}>
          Dismiss <FontAwesomeIcon icon={Icons.faTimes} />
        </span>
        <p>{parse(notification.message)}</p>
      </div>
    );
  });
  const showItem = !checkCurrentPath(MAIN_ROUTES, param.pathname);

  const showSidebarToggler = panel_role !== 'panel_restricted';

  return (
    <header className="header">
      <Navbar expand="lg">
        <div className="container-fluid p-0" role="tablist">
          <div className="d-flex align-items-center">
            {showSidebarToggler && (
              <span
                className="sidebar-toggle me-4 me-xl-4"
                onClick={() => setSideBarOpen((prev) => !prev)}
                aria-label="Mobile sidebar toggler"
              >
                <IconLayoutSidebarRightExpand stroke="1.5" size={24} />
              </span>
            )}
            <Navbar.Brand className="d-block d-lg-none" href="/dashboard">
              <img src={organization?.current_site_logo} alt={organization?.name} />
            </Navbar.Brand>
            {!showItem && (
              <div className="d-none d-lg-block">
                <small className="mb-0">Welcome</small>
                <h4>{name}</h4>
              </div>
            )}
          </div>

          {showItem && (
            <>
              <Navbar.Collapse id="edly-navbar">
                <Nav>
                  <a href={lmsUrl} className="nav-link" rel="noopener noreferrer" target="_blank">
                    LMS
                  </a>
                  <a href={studioUrl} className="nav-link" rel="noopener noreferrer" target="_blank">
                    Studio
                  </a>
                  {getWordpressBaseUrl() && (
                    <Fragment>
                      <a href={wordPressUrl} className="nav-link" rel="noopener noreferrer" target="_blank">
                        Discovery
                      </a>
                      <a
                        href={`${wordPressUrl}wp-admin/`}
                        className="nav-link"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Discovery Admin
                      </a>
                    </Fragment>
                  )}
                  {getEcommerceBaseUrl() && (
                    <Fragment>
                      <a
                        href={`${ecommerceUrl}courses/`}
                        className="nav-link"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        E-Commerce
                      </a>
                    </Fragment>
                  )}
                  {getHelpUrl() && (
                    <Fragment>
                      <a href={`${helpUrl}`} className="nav-link" rel="noopener noreferrer" target="_blank">
                        Help
                      </a>
                    </Fragment>
                  )}
                </Nav>
              </Navbar.Collapse>
            </>
          )}
          <div className={`additional-nav ${!showItem ? 'desktop-nav' : ''}`}>
            {showItem && (
              <Navbar.Toggle className="btn-icon p-0" aria-controls="basic-navbar-nav">
                <IconMenuDeep stroke={1.5} />
              </Navbar.Toggle>
            )}
            <NotificationsDropdown />
            <div className="d-none d-lg-flex">
              <UserGuideButton />
              {showItem && (mailChimpUrl !== '/#' || zenDeskUrl !== '/#' || googleDataStudioUrl !== '/#') && (
                <NavDropdown
                  className="btn-icon ms-2 ms-xl-3"
                  id="basic-nav-dropdown"
                  align="end"
                  title={
                    <span className="tb-span">
                      <IconCategory stroke={1.5} />
                    </span>
                  }
                >
                  <ul className="help-group">
                    {zenDeskUrl !== '/#' && (
                      <li>
                        <Nav.Link href={zenDeskUrl} target="_blank" rel="noopener noreferrer" className="dropdown-item">
                          <div className="img-holder">
                            <img src={ZendeskLogo} alt="Zendesk" />
                          </div>
                          Zendesk
                        </Nav.Link>
                      </li>
                    )}
                    {mailChimpUrl !== '/#' && (
                      <li>
                        <Nav.Link
                          href={mailChimpUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="dropdown-item"
                        >
                          <div className="img-holder">
                            <img src={MailChimpLogo} alt="Mailchimp" />
                          </div>
                          MailChimp
                        </Nav.Link>
                      </li>
                    )}
                    {googleDataStudioUrl !== '/#' && (
                      <li>
                        <Nav.Link
                          href={googleDataStudioUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="dropdown-item"
                        >
                          <div className="img-holder">
                            <img src={GoogleAnalyticsLogo} alt="Google Data Studio" />
                          </div>
                          Google Analytics
                        </Nav.Link>
                      </li>
                    )}
                  </ul>
                </NavDropdown>
              )}
            </div>
            <NavDropdown
              className="user-block"
              id="userInfoOptions"
              align="end"
              title={
                <div className="img-profile">
                  <img src={profile_image} alt="" />
                </div>
              }
            >
              <div className="user-drop">
                <div className="user-info">
                  <span>{name}</span>
                  <small className="user-email d-block">{email}</small>
                  <p className="d-block mt-2">{formatRole(panel_role)}</p>
                </div>
                <hr />
                <div className="d-flex d-lg-none">
                  <UserGuideButton />
                  {showItem && (mailChimpUrl !== '/#' || zenDeskUrl !== '/#' || googleDataStudioUrl !== '/#') && (
                    <NavDropdown
                      className="btn-icon ms-3"
                      id="basic-nav-dropdown"
                      align="end"
                      title={
                        <span className="tb-span">
                          <IconCategory stroke={1.5} />
                        </span>
                      }
                    >
                      <ul className="help-group">
                        {zenDeskUrl !== '/#' && (
                          <li>
                            <Nav.Link
                              href={zenDeskUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="dropdown-item"
                            >
                              <div className="img-holder">
                                <img src={ZendeskLogo} alt="Zendesk" />
                              </div>
                              Zendesk
                            </Nav.Link>
                          </li>
                        )}
                        {mailChimpUrl !== '/#' && (
                          <li>
                            <Nav.Link
                              href={mailChimpUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="dropdown-item"
                            >
                              <div className="img-holder">
                                <img src={MailChimpLogo} alt="Mailchimp" />
                              </div>
                              MailChimp
                            </Nav.Link>
                          </li>
                        )}
                        {googleDataStudioUrl !== '/#' && (
                          <li>
                            <Nav.Link
                              href={googleDataStudioUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="dropdown-item"
                            >
                              <div className="img-holder">
                                <img src={GoogleAnalyticsLogo} alt="Google Data Studio" />
                              </div>
                              Google Analytics
                            </Nav.Link>
                          </li>
                        )}
                      </ul>
                    </NavDropdown>
                  )}
                </div>
                <hr className="d-block d-lg-none" />
                <ul>
                  <li>
                    <Form className="switch-form">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Dark Mode"
                        onChange={toggleTheme}
                        checked={theme === 'dark'}
                      />
                    </Form>
                  </li>
                  <li
                    style={{ display: panel_role === 'panel_admin' || panel_role === 'super_admin' ? 'block' : 'none' }}
                  >
                    <Dropdown.Item href="/panel/settings/general">
                      <IconUser stroke={1.5} />
                      Profile
                    </Dropdown.Item>
                  </li>
                  <li>
                    <Dropdown.Item href={`${getPanelBackendUrl()}logout`} onClick={handleLogout}>
                      <IconPower stroke={1.5} />
                      Logout
                    </Dropdown.Item>
                  </li>
                </ul>
              </div>
            </NavDropdown>
          </div>
        </div>
      </Navbar>
      <div className={`system-notification ${display_status ? '' : 'd-none'}`}>{notifications}</div>
    </header>
  );
};

function mapStateToProps(state) {
  const organization = getOrganization(state);
  const { services_notifications, display_status } = state.SERVICES_NOTIFICATIONS;
  const { email, name, username } = getUserSettings(state);
  const { theme } = getTheme(state);
  return {
    organization,
    services_notifications,
    display_status,
    email,
    name: name || username,
    theme
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(notificationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
