import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col, Row, Alert } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { IconInfoTriangle } from '@tabler/icons-react';

import CreateSite from 'components/createSite';
import { createBillingSession } from 'pages/pricing/containers/pricingContainer/actions/pricing';
import PricingCalculator from 'pages/pricing/containers/pricingContainer/components/pricingCalculator';
import BillingDetails from 'pages/pricing/containers/pricingContainer/components/BillingDetails';
import PaymentMethod from 'pages/pricing/containers/pricingContainer/components/PaymentMethod';
import { TermsAndConditions } from 'pages/pricing/containers/pricingContainer/components/termsAndConditions';
import { pricingActions } from 'pages/pricing/containers/pricingContainer/actions/pricing';
import { getOrganization } from 'selectors';

const moment = require('moment');

const FlexiblePricing = (props) => {
  const { currentPlan, upComingInvoice } = props;
  const [businessModalShow, setBusinessModalShow] = useState(false);
  const [inputs, setInputs] = useState({
    business_name: '',
    address: '',
    apartment: '',
    country: '',
    city: '',
    zip: '',
    state: ''
  });

  function handleSubscribe() {
    setBusinessModalShow(true);
  }

  function showCreateSiteView() {
    setCreatingSite(true);
  }

  const [creatingSite, setCreatingSite] = useState(false);
  const isNotBilledMonthly = upComingInvoice?.billing_interval
    ? upComingInvoice.billing_interval !== 'month'
    : moment(moment()).diff(
        (upComingInvoice?.status_transitions?.finalized_at || upComingInvoice?.status_transitions?.period_start) * 1000,
        'days'
      ) > 31;

  return creatingSite ? (
    <CreateSite setCreatingSite={setCreatingSite} />
  ) : (
    <>
      <div className="mb-4 d-flex justify-content-between align-items-center flex-lg-row flex-column">
        <h2 className="mb-0">Billing Details</h2>
        {currentPlan !== 'trial' && (
          <Link to={'/panel/billing/details'}>
            <Button variant="link">View Billing history</Button>
          </Link>
        )}
      </div>
      {isNotBilledMonthly && (
        <div className="switch-billing-banner">
          <h5>
            You can now update to monthly billing <span>for free!</span>
          </h5>
          <p>
            Only the monthly active learners within that month will be billed. Contact our team now at{' '}
            <a className="link" onClick={() => window.HubSpotConversations.widget.open()}>
              customersuccess@edly.io.
            </a>
          </p>
        </div>
      )}
      <TermsAndConditions
        show={businessModalShow}
        setBusinessModalShow={setBusinessModalShow}
        onHide={() => {
          setBusinessModalShow(false);
        }}
        inputs={inputs}
        setInputs={setInputs}
        updateOrgTermsConditions={props.updateOrgTermsConditions}
      />
      <Row>
        <Col xs={12} md={{ span: 12 }}>
          <div className="current-billing">
            <BillingDetails creatingSite={creatingSite} setCreatingSite={setCreatingSite} />
            {currentPlan === 'subscription_cancelled' && (
              <div className="pt-2 pb-2">
                <Alert variant="danger">
                  <div className="custom-alert-area">
                    <div className="text-area">
                      <IconInfoTriangle className="me-3" stroke={1.5} />
                      <div className="text">
                        <h6 className="mb-1 alert-link">Your current billing is inactive</h6>
                        <small>Visit your customer portal to renew billing or contact our Support to learn more.</small>
                      </div>
                    </div>
                    <div>
                      <a
                        variant="link"
                        className="alert-link mt-2 mt-lg-0 fw-semibold"
                        onClick={(event) => {
                          event.preventDefault();
                          createBillingSession();
                        }}
                      >
                        Customer Portal
                      </a>
                      <a
                        variant="link"
                        className="alert-link mt-2 mt-lg-0 ms-4 fw-semibold"
                        onClick={() => window.HubSpotConversations.widget.open()}
                      >
                        Contact Sales
                      </a>
                    </div>
                  </div>
                </Alert>
              </div>
            )}
            <PaymentMethod handleSubscribe={handleSubscribe} showCreateSiteView={showCreateSiteView} />
          </div>
          <PricingCalculator handleSubscribe={handleSubscribe} />
        </Col>
      </Row>
    </>
  );
};

function mapStateToProps(state) {
  const { current_site_plan, upcoming_invoice } = getOrganization(state);
  return {
    currentPlan: current_site_plan,
    upComingInvoice: upcoming_invoice
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ ...pricingActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FlexiblePricing);
