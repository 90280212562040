import {
  pricingDispatches,
  termsConditionsDispatches,
  userSliderConstants
} from 'pages/pricing/containers/pricingContainer/constants';
import { pricingService } from 'services';
import { getPanelBackendUrl, messageNotification, validateLoginSession } from 'helpers';

const setPaymentMethod = (paymentMethodId) => {
  return (dispatch) => {
    pricingService
      .setPaymentMethod(paymentMethodId)
      .then((payload) => {
        messageNotification(
          'success',
          'Payment method has been added.',
          'Payment method added successfully in Stripe.'
        );
        dispatch(fetchPaymentMethod());
      })
      .catch((error) => {
        messageNotification('danger', 'There was an error adding payment method.', 'Stripe customer does not exist.');
      });
  };
};

const addCardInStripe = (payload, callback, callback_value) => {
  return (dispatch) => {
    pricingService
      .addCardInStripe(payload)
      .then((payload) => {
        if (payload.hasOwnProperty('paymentMethod')) {
          Promise.resolve(dispatch(setPaymentMethod(payload.paymentMethod.id))).then((r) => callback(callback_value));
        } else {
          messageNotification('danger', 'There was an error submitting card', payload.error.message);
        }
      })
      .catch((error) => {
        messageNotification('danger', 'There was an error submitting card', error);
      });
  };
};

const fetchPaymentMethod = () => {
  return (dispatch) => {
    pricingService
      .fetchPaymentMethod()
      .then((paymentMethod) => {
        Promise.resolve(dispatch(pricingDispatches.fetchPaymentMethodSuccess(paymentMethod)));
      })
      .catch((error) => {
        dispatch(pricingDispatches.fetchPaymentMethodFailure(error));
        validateLoginSession(dispatch, error);
        if (error) {
          messageNotification('danger', 'There was an error fetching payment method', error);
        }
      });
  };
};

const updateOrgTermsConditions = (accept_terms_conditions, inputs) => {
  return (dispatch) => {
    if (getPanelBackendUrl()) {
      pricingService.updateOrgTermsConditions(accept_terms_conditions, inputs).catch((error) => {
        if (error.response) {
          messageNotification('danger', 'There was an error accepting terms and conditions', error.response);
        }
      });
    } else {
      dispatch(termsConditionsDispatches.updateTermsConditionsFailure());
    }
  };
};

export const createBillingSession = () => {
  pricingService
    .billingPortalSession()
    .then((data) => {
      window.location.href = data.data.url;
    })
    .catch((error) => {
      messageNotification('danger', error.error || 'something went wrong creating session.');
    });
};

export const setUserSliderCount = (value) => ({
  type: userSliderConstants.SET_COUNT,
  payload: value
});

export const pricingActions = {
  setPaymentMethod,
  addCardInStripe,
  fetchPaymentMethod,
  updateOrgTermsConditions
};
