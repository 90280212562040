import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { getOrganization, getUserPanelRole, getUserSettings } from 'selectors';
import NavItem from 'components/sidebar/navItem';
import SettingsDropDown from 'components/sidebar/settingsDropDown';
import 'components/sidebar/index.scss';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {
  IconUsers,
  IconHome,
  IconCertificate,
  IconUserScreen,
  IconX,
  IconReceiptDollar,
  IconLibraryPlus
} from '@tabler/icons-react';
import { MAIN_ROUTES } from 'components/sidebar/contants';
import { checkCurrentPath } from 'helpers';

const SideBar = (props) => {
  const { sideBarOpen = true, panel_role, user_email, organization, setSideBarOpen = () => {} } = props;
  const location = useLocation();
  const [insightsOpen, setInsightsOpen] = useState(!!location.pathname.includes('insights'));
  const [settingsOpen, setSettingsOpen] = useState(!!location.pathname.includes('settings'));

  return (
    <div id="sidebar_content" className={`side-nav ${sideBarOpen ? 'open sidebar-open' : 'sidebar-close'}`}>
      <div className="logo-holder mb-4 mt-2">
        <Navbar.Brand className="d-none d-lg-block mb-2" href="/dashboard">
          <img src={organization?.current_site_logo} alt={organization?.name} />
        </Navbar.Brand>
        <span
          className="sidebar-toggle d-flex d-lg-none ms-3"
          onClick={() => setSideBarOpen((prev) => !prev)}
          aria-label="Mobile sidebar toggler"
        >
          <IconX stroke={1.5} />
        </span>
      </div>
      <Nav className="flex-column">
        <NavItem
          link="/panel/dashboard"
          label="Dashboard"
          fontIcon={<IconHome stroke={1.5} />}
          insightsOpen={false}
          setInsightsOpen={setInsightsOpen}
          settingsOpen={false}
          setSettingsOpen={setSettingsOpen}
          setSideBarOpen={setSideBarOpen}
        />
        {checkCurrentPath(MAIN_ROUTES, location.pathname) && (
          <>
            {panel_role === 'super_admin' && (
              <NavItem
                link="/panel/billing"
                label="Billing Details"
                fontIcon={<IconReceiptDollar stroke={1.5} />}
                className={location.pathname.includes('billing') ? 'active' : ''}
                insightsOpen={false}
                setInsightsOpen={setInsightsOpen}
                settingsOpen={false}
                setSettingsOpen={setSettingsOpen}
                setSideBarOpen={setSideBarOpen}
              />
            )}
            {panel_role !== 'insights_admin' && (
              <NavItem
                link="/panel/benefits"
                label="Benefits & Add Ons"
                fontIcon={<IconLibraryPlus stroke={1.5} />}
                className={location.pathname.includes('benefits') ? 'active' : ''}
                insightsOpen={false}
                setInsightsOpen={setInsightsOpen}
                settingsOpen={false}
                setSettingsOpen={setSettingsOpen}
                setSideBarOpen={setSideBarOpen}
              />
            )}
          </>
        )}

        {(panel_role === 'panel_admin' || panel_role === 'super_admin') &&
          !checkCurrentPath(MAIN_ROUTES, location.pathname) && (
            <NavItem
              link="/panel/users"
              label="Users"
              fontIcon={<IconUsers stroke={1.5} />}
              insightsOpen={false}
              setInsightsOpen={setInsightsOpen}
              settingsOpen={false}
              setSettingsOpen={setSettingsOpen}
              setSideBarOpen={setSideBarOpen}
            />
          )}

        {(panel_role === 'panel_admin' || panel_role === 'super_admin' || panel_role === 'insights_admin') &&
          !checkCurrentPath(MAIN_ROUTES, location.pathname) && (
            <>
              <NavItem
                link="/panel/insights/courses"
                label="Courses"
                className={location.pathname.includes('course') && insightsOpen ? 'active' : ''}
                insightsOpen={false}
                fontIcon={<IconCertificate stroke={1.5} />}
                setInsightsOpen={setInsightsOpen}
                settingsOpen={false}
                setSettingsOpen={setSettingsOpen}
                setSideBarOpen={setSideBarOpen}
              />
              <NavItem
                link="/panel/insights/learners"
                label="Learners"
                className={location.pathname.includes('learner') && insightsOpen ? 'active' : ''}
                insightsOpen={false}
                fontIcon={<IconUserScreen stroke={1.5} />}
                setInsightsOpen={setInsightsOpen}
                settingsOpen={settingsOpen}
                setSettingsOpen={setSettingsOpen}
                setSideBarOpen={setSideBarOpen}
              />
            </>
          )}

        <SettingsDropDown
          location={location}
          settingsOpen={settingsOpen}
          insightsOpen={false}
          setSettingsOpen={setSettingsOpen}
          setInsightsOpen={setInsightsOpen}
          panelRole={panel_role}
          userEmail={user_email}
          setSideBarOpen={setSideBarOpen}
        />
      </Nav>
    </div>
  );
};

function mapStateToProps(state) {
  const organization = getOrganization(state);
  const panel_role = getUserPanelRole(state);
  const user_email = getUserSettings(state).email;
  return {
    panel_role,
    user_email,
    organization
  };
}

export default connect(mapStateToProps, null)(SideBar);
