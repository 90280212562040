export const billingHistoryConstant = {
  FETCH_BILLING_HISTORY_SUCCESS: 'FETCH_BILLING_HISTORY_SUCCESS',
  FETCH_BILLING_HISTORY_FAILURE: 'FETCH_BILLING_HISTORY_FAILURE'
};

const fetchBillingHistorySuccess = (billingHistory) => {
  return { type: billingHistoryConstant.FETCH_BILLING_HISTORY_SUCCESS, payload: billingHistory };
};

const fetchBillingHistoryFailure = (error) => {
  return { type: billingHistoryConstant.FETCH_BILLING_HISTORY_FAILURE, payload: error };
};

export const billingHistoryDispatches = {
  fetchBillingHistorySuccess,
  fetchBillingHistoryFailure
};
