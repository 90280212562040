import React, { Fragment, useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { IconChevronLeft } from '@tabler/icons-react';
import 'react-datepicker/dist/react-datepicker.css';
import { Accordion, Card, ListGroup } from 'react-bootstrap';
import { IconCheck } from '@tabler/icons-react';

const moment = require('moment');

const DateFilter = (props) => {
  const {
    filterType,
    handleRefresh,
    handleFilterChange,
    handleSetStartDate,
    handleSetEndDate,
    setDefaultFilter
  } = props;

  const [startDate, setStartDate] = useState(
    moment()
      .subtract(1, 'months')
      .toDate()
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const filterContainerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterContainerRef.current && !filterContainerRef.current.contains(event.target)) {
        setIsFilterOpen(false);
        setIsDatePickerOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const applyButtonHandler = (e) => {
    handleRefresh(e);
    setIsFilterOpen(false);
  };

  const resetButtonHandler = (e) => {
    setDefaultFilter(e);
    setIsFilterOpen(false);
    setIsDatePickerOpen(false);
  };

  const handleStartDateChange = (date) => {
    handleSetStartDate(date);
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    handleSetEndDate(date);
  };

  const filters = {
    this_year: 'This Year',
    last_year: 'Last Year',
    this_month: 'This Month',
    last_month: 'Last Month'
  };

  const filterListItems = Object.keys(filters).map((key, index) => {
    return (
      <ListGroup.Item
        action
        className={filterType === key ? 'active' : ''}
        onClick={() => handleFilterChange(key)}
        key={key}
        style={{ fontSize: '0.75rem' }}
      >
        {filters[key]}
        {filterType === key && <IconCheck stroke={1.5} />}
      </ListGroup.Item>
    );
  });

  return (
    <Fragment>
      <div ref={filterContainerRef} className="maus-filter-container">
        <Accordion className="maus-filter">
          <Card>
            <Card.Header onClick={() => setIsFilterOpen(!isFilterOpen)} className={isFilterOpen ? 'open' : ''}>
              {filters[filterType] ? filters[filterType] : 'Custom Date'}{' '}
              <FontAwesomeIcon icon={isFilterOpen ? faChevronUp : faChevronDown} />
            </Card.Header>
            <Accordion.Collapse
              className={isFilterOpen ? 'show' : 'd-none'}
              style={{ minWidth: isDatePickerOpen ? '320px' : '320px' }}
            >
              <Card.Body>
                <ListGroup className="mb-3">{filterListItems}</ListGroup>
                <Button
                  variant="link"
                  size="sm"
                  className={filterType === 'custom' ? 'active' : 'p-0'}
                  onClick={() => {
                    handleFilterChange('custom');
                    setIsDatePickerOpen(true);
                  }}
                >
                  Custom Date Range {isDatePickerOpen}
                </Button>

                {isDatePickerOpen && (
                  <div className="date-range-container">
                    <div className="date-range-header">
                      <Button size="sm" onClick={() => setIsDatePickerOpen(false)} variant="outline-primary">
                        Back
                      </Button>
                    </div>
                    <div className="datepickers-holder">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => handleStartDateChange(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        inline
                      />
                      <div className="date-range-divider">
                        <span>To</span>
                      </div>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => handleEndDateChange(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        inline
                      />
                    </div>
                  </div>
                )}

                <div className="maus-filter-footer">
                  <Button variant="outline-primary" size="sm" onClick={resetButtonHandler}>
                    Reset
                  </Button>
                  <Button className="ms-2" variant="primary" size="sm" onClick={applyButtonHandler}>
                    Apply
                  </Button>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </Fragment>
  );
};

export default DateFilter;
