import React from 'react';

import { Tooltip, OverlayTrigger, Card, CardBody } from 'react-bootstrap';
import { IconInfoCircle } from '@tabler/icons-react';
import CourseListItem from 'pages/insights/containers/analyticsSummaryContainer/components/courseListItem';

const renderTooltip = (props) => (
  <Tooltip id="course-completions" {...props}>
    Courses ranked by number of completions.
  </Tooltip>
);

const TopCourseCompletions = (props) => {
  const { completion_results } = props;
  const courses = completion_results.slice(0, 10).map((course, index) => {
    return (
      <CourseListItem
        key={index}
        id={course.course_id}
        name={course.course_name}
        value={course.num_learners_completed}
      />
    );
  });

  return (
    <Card>
      <CardBody>
        <h5>
          Top Course by Completions
          <OverlayTrigger placement="bottom" overlay={renderTooltip}>
            <span id="course-completions" className="ms-2">
              <IconInfoCircle stroke={1.5} />
            </span>
          </OverlayTrigger>
        </h5>
        <ul className="course-analytics-list">
          <li className="head-list-item">
            <span>Name</span>
            <span>Completions</span>
          </li>
          {courses.length > 0 ? courses : <li className="no-data">No Data Available</li>}
        </ul>
      </CardBody>
    </Card>
  );
};

export default TopCourseCompletions;
