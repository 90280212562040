import { Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getBillingHistory } from 'selectors';
import Pagination from 'react-js-pagination';

import { billingHistoryAction } from 'pages/pricing/containers/invoicesContainer/actions/billingHistory';

const moment = require('moment');

const Invoices = (props) => {
  const { billingHistory, dispatch } = props;

  const handlePageChange = (pageNumber) => {
    let params = {};
    if (pageNumber !== 1) {
      if (billingHistory.currentPage < pageNumber) {
        params = { starting_after: billingHistory?.invoiceList[billingHistory?.invoiceList.length - 1]?.id };
      } else {
        params = { ending_before: billingHistory?.invoiceList[0]?.id };
      }
    }
    dispatch(billingHistoryAction.fetchBillingHistory(params, pageNumber));
  };

  return (
    <>
      {billingHistory?.invoiceList?.length !== 0 ? (
        <>
          <Table responsive className="align-middle">
            <thead>
              <tr>
                <th>Invoice Number</th>
                <th>Amount</th>
                <th>Payment Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {billingHistory?.invoiceList?.map((item, index) => {
                const date_paid = (item?.status_transitions?.finalized_at || item?.period_start) * 1000;
                return (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>
                      {new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }).format(item.amount_due / 100)}
                    </td>
                    <td>{moment(date_paid).format('DD-MM-YYYY')}</td>
                    <td style={{ textTransform: 'capitalize' }}>
                      <div className="d-flex align-items-center">
                        {item.status === 'paid' && <div className="circle-green"></div>}
                        {item.status === 'void' && <div className="circle-red"></div>}
                        {(item.status === 'open' || item.stauts === 'draft') && <div className="circle-orange"></div>}

                        <span className="ms-2">{item.status}</span>
                      </div>
                    </td>
                    <td>
                      <a href={item.hosted_invoice_url} className="link" rel="noopener noreferrer" target="_blank">
                        Download
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="d-flex justify-content-end">
            <Pagination
              activePage={billingHistory.currentPage}
              itemsCountPerPage={10}
              pageRangeDisplayed={1}
              totalItemsCount={
                billingHistory.hasMore ? billingHistory.currentPage * 10 + 10 : billingHistory.currentPage * 10
              }
              onChange={handlePageChange}
              hideFirstLastPages={true}
            />
          </div>
        </>
      ) : (
        <Row className="gap-3">
          <p>No record found</p>
        </Row>
      )}
    </>
  );
};

function mapStateToProps(state) {
  const billingHistory = getBillingHistory(state);
  return { billingHistory };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
