import React, { useEffect, useState } from 'react';
import { IconInfoCircle, IconReceipt } from '@tabler/icons-react';
import { connect } from 'react-redux';
import { Button, Card, Table } from 'react-bootstrap';
import getSymbolFromCurrency from 'currency-symbol-map';

import { messageNotification } from 'helpers';
import { getOrganization } from 'selectors';
import { mausService } from 'services/maus';
import { apiDateFormat, displayFormat, nanoSecondsToSecond } from 'pages/pricing/containers/pricingContainer/constants';
import { createBillingSession } from 'pages/pricing/containers/pricingContainer/actions/pricing';

const moment = require('moment');

const BillingDetails = (props) => {
  const { expected_billing, renewal_expiry, current_site_plan, active_subscription, upcoming_invoice } = props?.org;
  const [learnerData, setLearnerData] = useState({
    sub_organization: [],
    total_learners: 0,
    organization: ''
  });

  useEffect(() => {
    const fetchAllSiteData = async () => {
      const expiryDate = current_site_plan === 'trial' ? renewal_expiry : renewal_expiry * nanoSecondsToSecond;
      const endDate = moment(expiryDate);
      const payload = {
        type: 'custom',
        roles: 'learner',
        start_date: endDate
          .clone()
          .subtract(1, 'M')
          .format(apiDateFormat),
        course_activity_filter: false,
        end_date: endDate.format(apiDateFormat)
      };
      try {
        const response = await mausService.requestAllSiteMAUs(payload);
        setLearnerData(response.data);
      } catch (err) {
        messageNotification(
          'danger',
          'There was an error fetching MALs',
          err?.response?.data?.error || 'Error Fetching the site learners'
        );
      }
    };

    fetchAllSiteData();
  }, []);

  return (
    <>
      {!active_subscription && current_site_plan !== 'trial' && upcoming_invoice?.id ? (
        <div className="d-flex flex-column align-items-center mb-4">
          <h4 className="mb-4">
            Billing Details for <strong style={{ textTransform: 'capitalize' }}>{learnerData.organization}</strong>
          </h4>
          <Card style={{ maxWidth: '400px', width: '100%' }}>
            <Card.Body className="text-center p-4">
              <IconReceipt stroke={1} className="mb-2" style={{ width: '70px', height: '70px' }} />
              <small className="d-block mb-3">#{upcoming_invoice.id}</small>
              <a
                className="mb-4 d-inline-block btn btn-link btn-sm"
                href={upcoming_invoice?.hosted_invoice_url || '#'}
                rel="noopener noreferrer"
                target="_blank"
              >
                View Invoice
              </a>
              <div className="d-flex justify-content-between">
                <span>Payment Date</span>
                <span>
                  {moment(
                    (upcoming_invoice?.status_transitions?.finalized_at || upcoming_invoice?.period_start) * 1000
                  ).format('DD-MM-YYYY')}
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <span>Status</span>
                <span
                  className={`${
                    upcoming_invoice?.status === 'paid'
                      ? 'label-green'
                      : upcoming_invoice?.status === 'void'
                      ? 'label-red'
                      : 'label-orange'
                  }`}
                >
                  {upcoming_invoice?.status}
                </span>
              </div>
              <hr />
              <h2 className="text-center">
                {upcoming_invoice?.currency ? getSymbolFromCurrency(upcoming_invoice.currency.toUpperCase()) : '$'}
                {new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }).format(upcoming_invoice.amount_due / 100)}
              </h2>
              {!upcoming_invoice.paid && (
                <Button
                  className="d-block"
                  onClick={(event) => {
                    event.preventDefault();
                    createBillingSession();
                  }}
                >
                  Pay Now
                </Button>
              )}
            </Card.Body>
          </Card>
        </div>
      ) : (
        <>
          <h4 className="mb-4 d-flex align-items-center justify-content-between">
            <span>
              Billing Details for <strong style={{ textTransform: 'capitalize' }}>{learnerData.organization}</strong>
            </span>
          </h4>
          <Table responsive className="align-middle">
            <thead>
              <tr className="sites-data-head">
                <th>Sites</th>
                <th className="text-end">Monthly active learners</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan="2" style={{ padding: '0', border: 'none' }}>
                  <div style={{ maxHeight: '250px', overflowY: 'auto', margin: '10px 0' }}>
                    <Table className="align-middle m-0">
                      <tbody>
                        {learnerData?.sub_organization.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td className="text-end">{item.learner_count}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </td>
              </tr>

              <tr className="sites-data-head">
                <td>
                  <strong className="me-2">Total MAL's</strong>
                  <span className="tool-tip">
                    <IconInfoCircle stroke={1.5} />
                    <span className="tip-content">Monthly Active learners</span>
                  </span>
                </td>
                <td className="text-end">{learnerData.total_learners}</td>
              </tr>
              <tr>
                <td colSpan="2" className="text-end">
                  <div>
                    <span>
                      {current_site_plan === 'trial' ? (
                        <>
                          Plan Expiry Date: <strong>{`${moment(renewal_expiry).format(displayFormat)}`}</strong>
                        </>
                      ) : (
                        <>
                          Next Billing Date:{' '}
                          <strong>{` ${moment(renewal_expiry * 1000).format(displayFormat)}`}</strong>{' '}
                        </>
                      )}
                    </span>
                    <span className="ms-4">
                      Expected Bill:{' '}
                      <strong> ${(expected_billing / 100 || 0).toFixed(2).toLocaleString('en-US')}</strong>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  const org = getOrganization(state);
  return {
    org
  };
}

export default connect(mapStateToProps, null)(BillingDetails);
