import React from 'react';
import { IconInfoCircle } from '@tabler/icons-react';
import { Row, Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const PanelRolesButton = (props) => {
  const { inputs, handleInputChange } = props;
  const currentUserRole = useSelector((state) => state.USER_ROLES?.roles?.panel_role || '');
  const isSuperAdmin = currentUserRole === 'super_admin';
  const isEditingDisabled = inputs.panel_role === 'super_admin' && !isSuperAdmin;
  return (
    <Row className="mb-4">
      <Col xs="12" lg="4">
        <h6>Edly Panel Role</h6>
      </Col>
      <Col xs="12" lg="8">
        <Row>
          <Col xs="12" md="6" className="has-tip-info mb-2">
            {/* <input
              type="radio"
              name="panel_role"
              className="custom-control-input"
              id="panel_restricted_filter"
              onChange={handleInputChange}
              checked={inputs.panel_role.toString() === 'panel_restricted'}
              value="panel_restricted"
            /> */}
            <Form.Check
              type="radio"
              className="d-inline"
              name="panel_role"
              id="panel_restricted_filter"
              onChange={handleInputChange}
              checked={inputs.panel_role.toString() === 'panel_restricted'}
              value="panel_restricted"
              disabled={isEditingDisabled}
            />
            <Form.Check.Label
              className={`custom-control-label d-inline ms-2 ${inputs.is_active ? ' ' : 'disabled'} ${
                isEditingDisabled ? 'text-muted' : ''
              }`}
              htmlFor="panel_user_filter"
            >
              Restricted
              <span className="tool-tip">
                <IconInfoCircle stroke={1.5} />
                <span className="tip-content">
                  <span className="tip-content-item">User does not have access to Edly Panel</span>
                </span>
              </span>
            </Form.Check.Label>
          </Col>
          <Col xs="12" md="6" className="has-tip-info mb-2">
            {/* <input
              type="radio"
              name="panel_role"
              className="custom-control-input"
              id="panel_user_filter"
              onChange={handleInputChange}
              checked={inputs.panel_role.toString() === 'panel_user'}
              value="panel_user"
            /> */}
            <Form.Check
              type="radio"
              className="d-inline"
              name="panel_role"
              id="panel_user_filter"
              onChange={handleInputChange}
              checked={inputs.panel_role.toString() === 'panel_user'}
              value="panel_user"
              disabled={isEditingDisabled}
            />
            <Form.Check.Label
              className={`custom-control-label d-inline ms-2 ${inputs.is_active ? ' ' : 'disabled'} ${
                isEditingDisabled ? 'text-muted' : ''
              }`}
              htmlFor="panel_user_filter"
            >
              Panel User
              <span className="tool-tip">
                <IconInfoCircle stroke={1.5} />
                <span className="tip-content">
                  <span className="tip-content-item">User can access Edly Panel</span>
                  <span className="tip-content-item">User can view Quick Stats, and other basic information</span>
                  <span className="tip-content-item">User cannot edit anything or make any changes</span>
                </span>
              </span>
            </Form.Check.Label>
          </Col>

          <Col xs="12" md="6" className="has-tip-info mb-2">
            {/* <input
              type="radio"
              name="panel_role"
              className="custom-control-input"
              id="insights_admin_filter"
              onChange={handleInputChange}
              checked={inputs.panel_role.toString() === 'insights_admin'}
              value="insights_admin"
            /> */}
            <Form.Check
              type="radio"
              name="panel_role"
              className="d-inline"
              id="insights_admin_filter"
              onChange={handleInputChange}
              checked={inputs.panel_role.toString() === 'insights_admin'}
              value="insights_admin"
              disabled={isEditingDisabled}
            />
            <Form.Check.Label
              htmlFor="insights_admin_filter"
              className={`custom-control-label d-inline ms-2 ${inputs.is_active ? ' ' : 'disabled'}  ${
                isEditingDisabled ? 'text-muted' : ''
              }`}
            >
              Insights Viewer
              <span className="tool-tip">
                <IconInfoCircle stroke={1.5} />
                <span className="tip-content">
                  <span className="tip-content-item">User can access the Edly Panel Insights</span>
                  <span className="tip-content-item">
                    User can view Quick Stats, Insights and other basic information
                  </span>
                </span>
              </span>
            </Form.Check.Label>
          </Col>

          <Col xs="12" md="6" className="has-tip-info mb-2">
            {/* <input
              type="radio"
              name="panel_role"
              className="custom-control-input"
              id="panel_admin_filter"
              onChange={handleInputChange}
              checked={inputs.panel_role.toString() === 'panel_admin'}
              value="panel_admin"
            /> */}
            <Form.Check
              type="radio"
              name="panel_role"
              className="d-inline"
              id="panel_admin_filter"
              onChange={handleInputChange}
              checked={inputs.panel_role.toString() === 'panel_admin'}
              value="panel_admin"
              disabled={isEditingDisabled}
            />
            <Form.Check.Label
              className={`custom-control-label d-inline ms-2 ${inputs.is_active ? ' ' : 'disabled'} ${
                isEditingDisabled ? 'text-muted' : ''
              }`}
              htmlFor="panel_admin_filter"
            >
              Panel Admin
              <span className="tool-tip">
                <IconInfoCircle stroke={1.5} />
                <span className="tip-content">
                  <span className="tip-content-item">User can access the Edly Panel</span>
                  <span className="tip-content-item">
                    User can view Quick Stats, Pricing Plans, Insights and other basic information
                  </span>
                  <span className="tip-content-item">User can change themes and user roles</span>
                  <span className="tip-content-item">User can also add new users or delete existing ones</span>
                </span>
              </span>
            </Form.Check.Label>
          </Col>

          <Col xs="12" md="6" className="has-tip-info mb-2">
            <Form.Check
              type="radio"
              name="panel_role"
              className="d-inline"
              id="super_admin_filter"
              onChange={handleInputChange}
              checked={inputs.panel_role.toString() === 'super_admin'}
              value="super_admin"
              disabled={!isSuperAdmin}
            />
            <Form.Check.Label
              className={`custom-control-label d-inline ms-2 ${inputs.is_active ? ' ' : 'disabled'} ${
                !isSuperAdmin ? 'text-muted' : ''
              }`}
              htmlFor="super_admin_filter"
            >
              Panel Super Admin
              <span className="tool-tip">
                <IconInfoCircle stroke={1.5} />
                <span className="tip-content">
                  <span className="tip-content-item">User can access the Edly Panel</span>
                  <span className="tip-content-item">
                    User can view Quick Stats, Pricing Plans, Insights and other basic information
                  </span>
                  <span className="tip-content-item">User can change themes and user roles</span>
                  <span className="tip-content-item">User can also add new users or delete existing ones</span>
                  <span className="tip-content-item">User will receive emails about billing plans</span>
                </span>
              </span>
            </Form.Check.Label>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PanelRolesButton;
