import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import Header from 'components/header';
import Footer from 'components/footer';
import SideBar from 'components/sidebar';
import { getOrganization, getUserSettings, getUserLmsRole, getUserPanelRole } from 'selectors';
import CTABanner from 'components/ctaBanner';
import { MAIN_ROUTES } from 'components/sidebar/contants';
import { checkCurrentPath, checkUserRoleAccess } from 'helpers';

const ProtectedLayout = ({ children, ...props }) => {
  mixpanel.identify(props.email);
  mixpanel.people.set_once({
    $email: props.email,
    $name: props.username,
    department: props.lms_role
  });

  const { username, profile_image, panel_role, organization } = props;
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const location = useLocation();

  const handleLogout = (e) => {
    e.preventDefault();
    mixpanel.reset();
    localStorage.removeItem('edx_username');
    localStorage.removeItem('lms_csrf_token');
    localStorage.removeItem('panel_csrf_token');
    localStorage.removeItem('wordpress_base_url');
    localStorage.removeItem('ecommerce_base_url');
    window.location.href = e.currentTarget.getAttribute('href');
  };

  return checkUserRoleAccess(panel_role, location.pathname) ? (
    <div className={`${sideBarOpen ? 'side-bar-active' : 'side-bar-inactive'}`}>
      <a className="skip-link screen-reader-text" href="#content">
        Skip to content
      </a>
      <main
        className={
          window.location.pathname === '/panel/see_all_apps' || window.location.pathname === '/panel/notifications'
            ? 'main see-all-page'
            : 'main'
        }
        id="content"
      >
        <div className="sidebar">
          <SideBar sideBarOpen={sideBarOpen} setSideBarOpen={setSideBarOpen} />
        </div>
        <div id="page_container">
          <Header
            username={username}
            profile_image={profile_image}
            panel_role={panel_role}
            organization={organization}
            handleLogout={handleLogout}
            setSideBarOpen={setSideBarOpen}
          />
          <div className="plan-banner">
            <div className="plan-banner-holder">
              {['trial', 'deactivated'].includes(organization?.current_site_plan) && <CTABanner />}
            </div>
          </div>
          {window.location.pathname === '/panel/dashboard' ? (
            <div className="dashboard-content">
              <div className="main-content" id="page_main_content">
                <div className="dashboard-content-holder">{children}</div>
              </div>
            </div>
          ) : (
            <div className="main-content" id="page_main_content">
              {!checkCurrentPath(MAIN_ROUTES, location.pathname) && (
                <span className="organization-display">
                  <h5 className="mb-0 d-inline">{localStorage.getItem('organization_slug')}</h5>
                </span>
              )}
              <div className="main-content-holder">{children}</div>
            </div>
          )}
          <Footer />
        </div>
      </main>
    </div>
  ) : (
    <Redirect to={'/panel/dashboard'} />
  );
};

function mapStateToProps(state) {
  const organization = getOrganization(state);
  const { username, profile_image, email } = getUserSettings(state);
  const panel_role = getUserPanelRole(state);
  const lms_role = getUserLmsRole(state);
  return {
    username,
    panel_role,
    profile_image,
    organization,
    email,
    lms_role
  };
}

export default connect(mapStateToProps)(ProtectedLayout);
