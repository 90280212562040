import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useState } from 'react';
import AddOnModel from './addOnModel';

function BenefitsSlideContent({ item, button, isPaid }) {
  const [showModal, setShowModal] = useState(false);
  const [optionSelected, setSelectedOptions] = useState([]);

  const handleClose = () => setShowModal(false);
  const handleShow = () => {
    setShowModal(true);
    setSelectedOptions([item]);
  };

  return (
    <Col className={`${isPaid ? 'mb-0 col-12 col-md-12 col-lg-12 h-100' : 'mb-4 col-12 col-md-6 col-lg-4'}`}>
      <Card className={`${isPaid ? '' : 'h-100'}`}>
        <AddOnModel
          optionSelected={optionSelected}
          setSelectedOptions={setSelectedOptions}
          show={showModal}
          handleClose={handleClose}
        />
        <Card.Body className={`${button ? 'addon-card' : ''}`}>
          {isPaid && <span className="paid-label">Paid</span>}
          <Card.Title>{item.title}</Card.Title>
          <Card.Text style={{ fontSize: '0.875rem' }}>{item.description}</Card.Text>
          {button && (
            <div className="position-absolute bottom-0 start-0 end-0 ps-3 pe-3 pb-3">
              <Button className="d-block w-100" variant="outline-primary" onClick={handleShow}>
                {button}
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
}

export default BenefitsSlideContent;
