import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { siteDetailsActions } from '../actions';
import { userSiteActions } from 'pages/dashboard/containers/sites/actions/userSites';
import { BILLING_PAGE } from '../constants';
import DnsValues from './dnsValues';
import { Button, Row, Col } from 'react-bootstrap';
import { PANEL_BASE_URL } from 'config';
import { IconCircleCheck, IconLock } from '@tabler/icons-react';

const SiteDetailsForm = ({
  dispatch,
  dnsLoading,
  dnsList,
  showSave,
  isTrialUser,
  loading,
  secondJobLoading,
  showSiteValues,
  email: propEmail,
  siteUrl: propSiteUrl
}) => {
  const [siteUrl, setSiteUrl] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(dnsLoading || loading);
  const [showDnsTable, setShowDnsTable] = useState(false);
  const [isValid, setIsValid] = useState({ isUrlValid: true, isEmailValid: true });

  const urlPattern = /^[a-zA-Z0-9.-]+$/;
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validateUrl = () => {
    setIsValid((prevState) => ({
      ...prevState,
      isUrlValid: urlPattern.test(siteUrl)
    }));
  };

  const validateEmail = () => {
    setIsValid((prevState) => ({
      ...prevState,
      isEmailValid: emailPattern.test(email)
    }));
  };

  useEffect(() => {
    let intervalId;

    if (isLoading) {
      intervalId = setInterval(fetchSiteDetails, 10000);
    }
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    let intervalId;

    if (secondJobLoading) {
      intervalId = setInterval(fetchUpdatedState, 5000);
    }
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, [secondJobLoading]);

  useEffect(() => {
    setIsLoading(dnsLoading || loading);
  }, [dnsLoading, loading]);

  useEffect(() => {
    setEmail(propEmail ? propEmail : '');
    setSiteUrl(propSiteUrl ? propSiteUrl : '');
  }, [propEmail, propSiteUrl]);

  const fetchSiteDetails = () => {
    dispatch(siteDetailsActions.fetchSiteDetails());
  };

  const fetchUpdatedState = () => {
    dispatch(siteDetailsActions.fetchUpdatedState());
  };

  const hideDnsTable = () => {
    setShowDnsTable(false);
  };

  const generateDns = () => {
    if (siteUrl && email) {
      dispatch(siteDetailsActions.postSiteData({ site_url: siteUrl, email: email }));
    }
  };

  return (
    <>
      <Row>
        <Col xs="12" className="mb-4">
          <label className="form-label">My edly site URL</label>
          <div className="form-group lock-fields">
            {isTrialUser && <IconLock stroke={1.5} />}
            <input
              className={`form-control ${!isValid.isUrlValid ? 'mb-10' : ''}`}
              value={siteUrl}
              placeholder={'ab-courses.edly.com'}
              onChange={(event) => setSiteUrl(event.target.value)}
              disabled={isTrialUser || isLoading || showSave}
              onBlur={validateUrl}
            ></input>
            {!isValid.isUrlValid && <span className="text-error">URL is not valid!</span>}
          </div>
        </Col>

        <Col xs="12" className="mb-4">
          <label className="form-label">User facing emails from email</label>
          <div className="form-group lock-fields">
            {isTrialUser && <IconLock stroke={1.5} />}
            <input
              className={`form-control ${!isValid.isEmailValid ? 'mb-10' : ''}`}
              value={email}
              placeholder={'xyz@gmail.com'}
              onChange={(event) => setEmail(event.target.value)}
              disabled={isTrialUser || isLoading || showSave}
              onBlur={validateEmail}
            ></input>
            {!isValid.isEmailValid && <span className="text-error">Email is not valid!</span>}
          </div>
        </Col>

        {isTrialUser && (
          <Col xs="12" className="mb-4">
            <span className="mr-10">This feature is not availabe for free plan: </span>
            <Link className="link" to={BILLING_PAGE}>
              Upgrade Now
            </Link>
          </Col>
        )}

        <Col xs="12" className="d-flex justify-content-end">
          <Button
            variant="outline-primary"
            onClick={() => {
              dispatch(userSiteActions.fetchUserSites());
              showSiteValues();
            }}
          >
            Cancel
          </Button>
          {!showSave && (
            <Button
              className="ms-2"
              onClick={generateDns}
              disabled={isTrialUser || isLoading || !isValid.isUrlValid || !isValid.isEmailValid}
              style={{
                position: 'relative'
              }}
            >
              {isLoading && <div className="loader" />}
              <span className={isLoading ? 'ps-4' : ''}> generate DNS values</span>
            </Button>
          )}

          {showSave && (
            <>
              <Button className="ms-2" onClick={() => setShowDnsTable(true)}>
                <IconCircleCheck stroke={1.5} />
                <span> View DNS values</span>
              </Button>
            </>
          )}
        </Col>
        <div>
          <div></div>
        </div>
      </Row>
      {showDnsTable && <DnsValues hideDnsTable={hideDnsTable} dnsList={dnsList} />}
    </>
  );
};

function mapStateToProps(state) {
  const { siteUrl, email, showSave, loading, dnsList, secondJobLoading } = state.SITE_DETAILS;
  const { data, dnsLoading } = state.DNS_ENTRIES;
  const { isTrialUser } = state.FREE_TRIAL;

  return {
    data,
    dnsLoading,
    isTrialUser,
    siteUrl,
    email,
    showSave,
    loading,
    dnsList,
    secondJobLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(siteDetailsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteDetailsForm);
