import { pricingService } from 'services';
import { billingHistoryDispatches } from 'pages/pricing/containers/invoicesContainer/constants';
import { messageNotification, validateLoginSession } from 'helpers';

const fetchBillingHistory = (params, currentPage = 1) => {
  return (dispatch) => {
    pricingService
      .fetchBillingHistory(params)
      .then((data) => {
        Promise.resolve(
          dispatch(billingHistoryDispatches.fetchBillingHistorySuccess({ data, currentPage: currentPage || 1 }))
        );
      })
      .catch((error) => {
        dispatch(billingHistoryDispatches.fetchBillingHistoryFailure(error));
        validateLoginSession(dispatch, error);
        if (error) {
          messageNotification('danger', 'There was an error fetching billing details', error);
        }
      });
  };
};

export const billingHistoryAction = {
  fetchBillingHistory
};
