import React from 'react';

import BenefitsSlideContent from './benefitsSlideContent';
import { paidBenefitList } from '../constants/benefitsContent';
import Slider from 'react-slick';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

function PaidBenefits() {
  const benefits = paidBenefitList.map((item, index) => (
    <BenefitsSlideContent key={index} item={item} button={'Request Add On'} isPaid={true} />
  ));

  const settings = {
    infinite: false,
    slidesToShow: 3,
    adaptiveHeight: true,
    slidesToScroll: 1,
    centerPadding: '0',
    prevArrow: <IconChevronLeft stroke={1.5} />,
    nextArrow: <IconChevronRight stroke={1.5} />,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true
        }
      }
    ]
  };

  return (
    <>
      <h2 className="mb-4">Benefits & Add Ons</h2>
      <div className="addons-holder">
        <h4>Add Ons</h4>
        <Slider {...settings}>{benefits}</Slider>
      </div>
    </>
  );
}

export default PaidBenefits;
