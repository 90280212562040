import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import PDFHeader from 'components/pdf/header';
import PDFFooter from 'components/pdf/footer';
import FooterNote from 'components/footer/footerNote';
import InsightsLoader from 'components/loaders/InsightsLoader';
import LearnerCoursesListing from 'pages/insights/containers/learnerDetailContainer/components/learnerCoursesListing';
import LearnerInfo from 'pages/insights/containers/learnerDetailContainer/components/learnerInfo';
import DownloadLearnerDetails from 'pages/insights/containers/learnerDetailContainer/components/downloadLearnerDetails';
import { learnerDetailsActions } from 'pages/insights/containers/learnerDetailContainer/actions';
import { getCurrentViewedLearnerDetails } from 'selectors';
import 'pages/insights/containers/learnerDetailContainer/learnerDetail.scss';

const LearnerDetails = (props) => {
  const { learner_info, dispatch } = props;
  const { username } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    dispatch(learnerDetailsActions.fetchLearnerDetails({ username: username }, stopLoader));
  }, [dispatch, username]);

  const stopLoader = () => {
    setIsLoaded(true);
  };

  return (
    <div id="page_content">
      {isLoaded ? (
        <Fragment>
          <PDFHeader />
          <div className="page-content-header">
            <h2 className="mb-lg-0">
              {learner_info ? (learner_info.name ? learner_info.name : learner_info.username) : ''}
            </h2>
            <DownloadLearnerDetails learner_info={learner_info} dispatch={dispatch} />
          </div>
          <div className="learner-details-container row-view">
            <LearnerInfo learner_info={learner_info} />
            <div className="table-container table-holder mb-4">
              <Table size="sm" className="align-middle">
                <thead>
                  <tr>
                    <th>Course Title</th>
                    <th className="text-center">Enrollment Date</th>
                    <th className="text-center">Enrollment Status</th>
                    <th className="text-center">Completion Date</th>
                    <th className="text-center">Grade</th>
                    <th className="text-center">Graded Course Progress</th>
                    <th className="text-center">Total Course Progress</th>
                  </tr>
                </thead>
                <tbody>
                  {learner_info && learner_info.courses && (
                    <LearnerCoursesListing courses_data={learner_info.courses} />
                  )}
                </tbody>
              </Table>
              {learner_info && learner_info.courses && learner_info.courses.length === 0 ? (
                <span className="no-data">No Data Available</span>
              ) : (
                ''
              )}
            </div>
          </div>
          <FooterNote />
          <PDFFooter />
        </Fragment>
      ) : (
        <InsightsLoader />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const learner_info = getCurrentViewedLearnerDetails(state);

  return {
    learner_info
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(learnerDetailsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnerDetails);
