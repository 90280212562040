import { useState } from 'react';
import { connect } from 'react-redux';
import { CardBody, Card, Button, Row, Col } from 'react-bootstrap';

import stripeLogo from 'assets/images/stripe.svg';
import usdLogo from 'assets/images/usd.svg';
import { PANEL_TLD_DOMAIN } from 'config';
import { messageNotification } from 'helpers';
import { createBillingSession } from 'pages/pricing/containers/pricingContainer/actions/pricing';
import { getOrganization, getPaymentMethod, getUserPanelRole } from 'selectors';

const PaymentMethod = (props) => {
  const { org, panel_role, paymentMethod, showCreateSiteView } = props;
  const isCreateSiteVisible = window.location.origin.includes(PANEL_TLD_DOMAIN) && panel_role === 'super_admin';
  const handleNavigateToBilling = async (event) => {
    event.preventDefault();
    createBillingSession();
  };

  return (
    <div>
      <h4>Payment Method</h4>
      <Row className="payment-method-holder mb-0 mb-lg-4">
        {paymentMethod?.paymentCardDigits ? (
          <Col sm="12" lg="6" className="mb-4 mb-lg-0">
            <Card>
              <CardBody className="p-4">
                <div className="card-holder mb-1">
                  <img className="me-md-4 me-0 mb-4 mb-md-0" src={stripeLogo} width="60" alt="stripe logo" />
                  <div>
                    <div className="card-title">
                      <h6 className="mb-0 me-2">{paymentMethod.userName}</h6>
                      <img src={usdLogo} alt="USD logo" />
                    </div>
                    <p className="mb-0">●●●● ●●●● ●●●● {paymentMethod.paymentCardDigits}</p>
                  </div>
                </div>
                <Button variant="link" onClick={handleNavigateToBilling}>
                  Customer Portal
                </Button>
              </CardBody>
            </Card>
          </Col>
        ) : !['trial', 'deactivated'].includes(org?.current_site_plan) ? (
          <Col sm="12" lg="6" className="mb-4 mb-lg-0">
            <Card>
              <CardBody className="d-flex align-items-center justify-content-center">
                <Button variant="link" onClick={handleNavigateToBilling}>
                  Customer Portal
                </Button>
              </CardBody>
            </Card>
          </Col>
        ) : (
          <Col sm="12" lg="6" className="mb-4 mb-lg-0">
            <Card>
              <CardBody className="d-flex align-items-center justify-content-center">
                <Button variant="link" onClick={props.handleSubscribe}>
                  Activate Billing
                </Button>
              </CardBody>
            </Card>
          </Col>
        )}
        {isCreateSiteVisible && (
          <Col sm="12" lg="6" className="mb-4 mb-lg-0">
            <Card>
              <CardBody className="d-flex align-items-center justify-content-center">
                <Button
                  variant="link"
                  onClick={() => {
                    if (['trial'].includes(org.current_site_plan)) {
                      messageNotification(
                        'info',
                        'Activate Billing',
                        'Please activate your billing to create a new site.'
                      );
                    } else {
                      showCreateSiteView();
                    }
                  }}
                >
                  Create New Site
                </Button>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

function mapStateToProps(state) {
  const paymentMethod = getPaymentMethod(state);
  const panel_role = getUserPanelRole(state);
  const org = getOrganization(state);

  return {
    paymentMethod,
    org,
    panel_role
  };
}

export default connect(mapStateToProps, null)(PaymentMethod);
