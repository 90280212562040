export const CTA_APPEAR_AFTER_DAYS_COUNT = 0;
export const CTA_BANNER_FREE_TRIAL_MSG = 'Your free trial account will be expired in {dayRemainings} days';
export const CTA_BANNER_EXPIRED_MSG = 'Free trial expired';

export const CTA_BANNER_TRIAL_DETAIL_MSG =
  'Free trial account duration is 30 days, including one active site and 20 learners';
export const CTA_BANNER_EXPIRED_DETAIL_MSG =
  'Free trial account duration is 30 days, including one active site and 20 learners';

export const BILLING_PAGE = '/panel/billing';
