import { Button, Modal } from 'react-bootstrap';
import { IconInfoCircle } from '@tabler/icons-react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ltiActions } from 'pages/integrations/containers/LtiIntegrationContainer/actions';
const Confirmation = ({ isOpen, hideModal, deleteParams, dispatch }) => {
  const deleteLtiConfiguration = () => {
    dispatch(ltiActions.deleteLtiConfiguration(deleteParams));
    hideModal();
  };

  return (
    <Modal onHide={hideModal} show={isOpen} backdrop="static" size="md">
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ minHeight: '10px' }}>
        <IconInfoCircle stroke={1.5} /> &nbsp; Are you sure you want to delete it?
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={deleteLtiConfiguration} disabled={false}>
          Yes, delete it
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(ltiActions, dispatch)
  };
}
export default connect(mapDispatchToProps)(Confirmation);
